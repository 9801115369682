import React from "react"

import Seo from "../components/seo"
import FrontPage from "./front-page"

const IndexPage = () => (
  <>
    <Seo title="Shakes and Smoothies" />
    <FrontPage />
  </>
)

export default IndexPage
