import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Row from "../../../styles/Row"
import { hex2rgba } from "../../../styles/tools"
import ArrowRight from "../../../images/arrow-right.svg"

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding: 2rem 0;

  @media (max-width: 540px) {
    padding: 1rem 0;
  }

  .gatsby-image-wrapper {
    margin: 0.75rem;
    width: 200px;
    /* height: 240px; */

    border-radius: 1.5rem;
    box-shadow: 0 2px 8px 4px
      ${props => hex2rgba(props.theme.color.primary, 0.1)};

    background-color: ${props => props.theme.color.whiteBg};

    @media (max-width: 540px) {
      margin: 0.5rem;
      width: 140px;

      &:nth-child(5) {
        display: none;
      }
    }
  }
`
const Content = styled.div`
  margin-bottom: 3rem;
  text-align: center;

  h2 {
    display: block;
    margin-bottom: 0.5rem;
  }

  a {
    display: inline-flex;
    align-items: center;
    font-style: italic;

    svg {
      display: block;
      margin-left: 0.5rem;

      .arrow-right {
        fill: ${props => props.theme.color.primary};
      }
    }
  }
`

export default function Instagram(props) {
  const images = useStaticQuery(graphql`
    query frontPageInstagramImages {
      image1: file(relativePath: { eq: "instagram/instagram-01.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 200, placeholder: BLURRED)
        }
      }
      image2: file(relativePath: { eq: "instagram/instagram-02.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 200, placeholder: BLURRED)
        }
      }
      image3: file(relativePath: { eq: "instagram/instagram-03.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 200, placeholder: BLURRED)
        }
      }
      image4: file(relativePath: { eq: "instagram/instagram-04.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 200, placeholder: BLURRED)
        }
      }
      image5: file(relativePath: { eq: "instagram/instagram-05.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 200, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <Row>
      <Wrapper>
        <a
          href="https://www.instagram.com/p/C6e2fNvvlce/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GatsbyImage
            image={images.image1.childImageSharp.gatsbyImageData}
            alt="Instagram"
          />
        </a>

        <a
          href="https://www.instagram.com/p/C51wQuYNAsr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GatsbyImage
            image={images.image2.childImageSharp.gatsbyImageData}
            alt="Instagram"
          />
        </a>

        <a
          href="https://www.instagram.com/p/C4qpuezPFT0/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GatsbyImage
            image={images.image3.childImageSharp.gatsbyImageData}
            alt="Instagram"
          />
        </a>

        <a
          href="https://www.instagram.com/p/C4doQK6Ln_K/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GatsbyImage
            image={images.image4.childImageSharp.gatsbyImageData}
            alt="Instagram"
          />
        </a>

        <a
          href="https://www.instagram.com/p/C4Yzp8APfxF/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <GatsbyImage
            image={images.image5.childImageSharp.gatsbyImageData}
            alt="Instagram"
          />
        </a>
      </Wrapper>

      <Content>
        <h2>
          <a
            href="https://www.instagram.com/therealfreal/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontStyle: "normal" }}
          >
            We're @therealfreal
          </a>
        </h2>
        <a
          href="https://www.instagram.com/therealfreal/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Follow us on instagram <ArrowRight />
        </a>
      </Content>
    </Row>
  )
}
