import React from "react"

import Transition from "./Transition/"
import Hero from "./Hero/"
import HowItWorks from "./HowItWorks/"
import Flavors from "./Flavors"
import Instagram from "./Instagram"

export default function FrontPage(props) {
  const isSSR = typeof window === "undefined"

  return (
    <>
      <Hero />

      <Transition transitionId={`980sd7fs9df8908`} />

      {!isSSR && (
        <>
          <HowItWorks />
          <Flavors />
        </>
      )}

      <Transition reverse={true} transitionId={`d89f7gd98f7g`} />

      {!isSSR && <Instagram />}
    </>
  )
}
